import React, { Fragment, PureComponent } from 'react'
import { Layout, Jumbotron, Sides, Marks } from '../../../components'
import '../../style.less'

class VeganVegetarian extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Vegan & Vegetarian Dishes'}
          location={this.props.location}
        >
          <Jumbotron
            heading={'Vegan & Vegetarian Dishes'}
            image={'vegan.png'}
          />
          <div className='container'>
            <Marks />
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Thai Style Butternut Squash Curry <span className='vg-mark'>VG</span> <span className='gf-mark'>GF</span><p className='pull-right'>£15.95</p></h3>
                  <p>Made To Our Own Recipe, Served With Rice &amp; Crackers. <br/><i style={{ color: '#ff7700', fontSize: '14px' }}><br className='brnodisplay'></br>Mildly Spicy</i></p>
                </div>
                <div className='para-h4'>
                  <h3 className='para-h2'>Vegan Breaded Schnitzel <span className='vg-mark'>VG</span><p className='pull-right'>£14.95</p></h3>
                  <p>Schnitzel Served With Mash, Seasonal Vegetables & Gravy</p>
                </div>
                <div className='para-h4'>
                  <h3 className='para-h2'>Penne Pasta <span className='vg-mark'>VG</span><p className='pull-right'>£13.95</p></h3>
                  <p>Penne Cooked In A Rich Tomato, Black Olives, Garlic &amp; Coriander Sauce Served With Ciabatta Garlic Bread</p>
                </div>
              </div>
            </div>
            <Sides />
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default VeganVegetarian
